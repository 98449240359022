import axios, { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import { ConversationItem } from './chatTypes';
import {
    Channel,
    Currency,
    Partner,
    PartnerConfig,
    PartnerPlatformConfig,
    PartnerProfile,
    PartnerReport,
    PartnerSale,
    RampartAgent,
    RampartAgentForms,
    RampartCustomer,
    RampartCustomerForms,
    RampartLLCLead,
    RampartMVRLead,
    SendRampartForm,
} from './types';

const { publicRuntimeConfig } = getConfig();

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
    config.headers['X-Request-ID'] = uuidv4();
    return config;
});

export const api = {
    // used on SSR pages and needs absolute URL
    getPartnerConfig: async (partnerId: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerId}/config`;
        return axiosInstance.get<PartnerConfig>(url);
    },

    addFacebookPageToPartner: async (
        partnerId: string,
        pages: Record<string, string>,
        sessionToken: string
    ) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerId}/facebook`;
        return axiosInstance.post<PartnerPlatformConfig>(url, pages, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },

    requestPartnerLoginLink: async (email: string) => {
        const url = `/rest/partners`;
        return axiosInstance.post(url, { email });
    },

    loginWithPartnerActivationCode: async (code: string) => {
        const url = `/rest/partners/login`;
        return axiosInstance.post(url, { activation_code: code });
    },

    partnerLogout: async () => {
        const url = `/rest/partners/logout`;
        return axiosInstance.post(url);
    },

    getCurrentPartner: async (sessionToken?: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/me`;
        return axiosInstance.get<Partner>(url, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },

    getPartnerReports: async (partnerID: string, sessionToken: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerID}/reports`;
        return axiosInstance.get<Array<PartnerReport>>(url, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },

    getPartnerSales: async (partnerID: string, sessionToken: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerID}/sales`;
        return axiosInstance.get<Array<PartnerSale>>(url, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },

    getPartnerConfigClient: async (partnerId: string) => {
        const url = `/rest/partners/${partnerId}/config`;
        return axiosInstance.get<PartnerConfig>(url);
    },

    updatePartnerConfigClient: async (
        partnerId: string,
        partnerConfig: { config?: PartnerConfig; profile?: PartnerProfile }
    ) => {
        const url = `/rest/partners/${partnerId}`;
        return axiosInstance.post<{ config: PartnerConfig; profile: PartnerProfile }>(
            url,
            partnerConfig
        );
    },

    updatePartnerConfig: async (partnerId: string, partnerConfig: PartnerConfig) => {
        const url = `/rest/partners/${partnerId}/config`;
        return axiosInstance.patch<PartnerConfig>(url, partnerConfig);
    },

    getPartnerPlatforms: async (partnerID: string, sessionToken: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerID}/platforms`;
        return axiosInstance.get<PartnerPlatformConfig>(url, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },
    getCurrencies: async () => {
        const url = `/rest/currencies`;
        return axiosInstance.get<Array<Currency>>(url);
    },

    getChannelChatHistory: async (partnerID: string, channelID: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerID}/channels/${channelID}/conversation`;
        return axiosInstance.get<{ messages: Array<ConversationItem> }>(url);
    },

    getPartnerChannels: async (partnerID: string, sessionToken: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/partners/${partnerID}/channels`;
        return axiosInstance.get<Array<Channel>>(url, {
            headers: {
                Cookie: `partner_session_token=${sessionToken};`,
            },
        });
    },

    getRampartMVRLead: async (leadID: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/rampart/mvr/${leadID}`;
        return axiosInstance.get<RampartMVRLead>(url);
    },

    updateRampartMVRLead: async (leadID: string, rampartLead: RampartMVRLead) => {
        const url = `/rest/rampart/mvr/${leadID}`;
        return axiosInstance.patch<RampartMVRLead>(url, rampartLead);
    },

    createRampartMVRLead: async (rampartLead: RampartMVRLead) => {
        const url = `/rest/rampart/mvr`;
        return axiosInstance.post<RampartMVRLead>(url, rampartLead);
    },

    getRampartLLCLead: async (leadID: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/rampart/llc/${leadID}`;
        return axiosInstance.get<RampartLLCLead>(url);
    },

    updateRampartLLCLead: async (leadID: string, rampartLead: RampartLLCLead) => {
        const url = `/rest/rampart/llc/${leadID}`;
        return axiosInstance.patch<RampartLLCLead>(url, rampartLead);
    },

    createRampartLLCLead: async (rampartLead: RampartLLCLead) => {
        const url = `/rest/rampart/llc`;
        return axiosInstance.post<RampartLLCLead>(url, rampartLead);
    },

    getRampartAgentForms: async (agentID: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/rampart/agent/${agentID}/forms`;
        return axiosInstance.get<RampartAgentForms>(url);
    },

    getRampartCustomerForms: async (customerID: string) => {
        const url = `${publicRuntimeConfig?.rootUrl}/rest/rampart/customer/${customerID}/forms`;
        return axiosInstance.get<RampartCustomerForms>(url);
    },

    loginRampartCustomer: async (email: string) => {
        const url = `/rest/rampart/customer/login`;
        return axiosInstance.post<RampartCustomer>(url, { email });
    },

    loginRampartAgent: async (email: string) => {
        const url = `/rest/rampart/agent/login`;
        return axiosInstance.post<RampartAgent>(url, { email });
    },

    sendCustomerForm: async (form: SendRampartForm) => {
        const url = `/rest/rampart/agent/${form.agent_id}/forms`;
        return axiosInstance.post<SendRampartForm>(url, form);
    },
};
